<template>
  <div>
    <slot name="ProgressBar"/>
    <page-header
      :title="`부가 정보를 알려주세요.`"
    >
    </page-header>

    <h3>선호하는 성별</h3>

    <tag-box>
      <li @click="formData.wgender = '여성'" :class="{ on: formData.wgender === '여성' }" >여성</li>
      <li @click="formData.wgender = '남성'" :class="{ on: formData.wgender === '남성' }" >남성</li>
      <li @click="formData.wgender = '무관'" :class="{ on: formData.wgender === '무관' }" >무관</li>
    </tag-box>

    <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

    <h3>선호하는 연령대</h3>

    <tag-box>
      <li @click="formData.wage = '10대'" :class="{ on: formData.wage === '10대' }" >10대</li>
      <li @click="formData.wage = '20대'" :class="{ on: formData.wage === '20대' }" >20대</li>
      <li @click="formData.wage = '30대'" :class="{ on: formData.wage === '30대' }" >30대</li>
      <li @click="formData.wage = '40대'" :class="{ on: formData.wage === '40대' }" >40대</li>
      <li @click="formData.wage = '50대'" :class="{ on: formData.wage === '50대' }" >50대</li>
      <li @click="formData.wage = '60대 이상'" :class="{ on: formData.wage === '60대 이상' }" >60대 이상</li>
      <li @click="formData.wage = '무관'" :class="{ on: formData.wage === '무관' }" >무관</li>
    </tag-box>

    <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

    <h3>교통비는 예산에 포함인가요?</h3>

    <tag-box>
      <li @click="formData.wtransCost = '포함'" :class="{ on: formData.wtransCost === '포함' }" >포함</li>
      <li @click="formData.wtransCost = '별도 지급'" :class="{ on: formData.wtransCost === '별도 지급' }" >별도 지급</li>
    </tag-box>

    <template v-if="postOffer.eventType === '모델'">
      <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

      <h3>헤어 스타일링은 제공해주시나요?</h3>

      <tag-box>
        <li @click="formData.whairStyle = '제공'" :class="{ on: formData.whairStyle === '제공' }" >제공</li>
        <li @click="formData.whairStyle = '전문가가 준비'" :class="{ on: formData.whairStyle === '전문가가 준비' }" >전문가가 준비</li>
      </tag-box>

      <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

      <h3>메이크업은 제공해주시나요?</h3>

      <tag-box>
        <li @click="formData.wmakeUp = '제공'" :class="{ on: formData.wmakeUp === '제공' }" >제공</li>
        <li @click="formData.wmakeUp = '전문가가 준비'" :class="{ on: formData.wmakeUp === '전문가가 준비' }" >전문가가 준비</li>
      </tag-box>
    </template>

    <template v-if="postOffer.genre === '노래'">
      <div class="ui-border-line ui-mt-1 ui-mb-1"></div>

      <h3>음향 장비는 제공해주시나요?</h3>

      <tag-box>
        <li @click="formData.wmic = '제공'" :class="{ on: formData.wmic === '제공' }" >제공</li>
        <li @click="formData.wmic = '전문가가 준비'" :class="{ on: formData.wmic === '전문가가 준비' }" >전문가가 준비</li>
      </tag-box>
    </template>

    <div class="ui-border-line ui-h-0 ui-mb-6"></div>

    <footer-box
      submitText="다음"
      :submitCb="() => nextStep()"
      :submitDisabled="!validate"
    >
    </footer-box>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import TagBox from '@/components/common/TagBox'
import FooterBox from '@/components/common/FooterBox'

export default {
  components: {
    PageHeader,
    TagBox,
    FooterBox,
  },
  data() {
    const postOffer = this.$store.state.postOffer
    let formData = {
      wgender: postOffer.wgender || null,
      wage: postOffer.wage || null,
      wtransCost: postOffer.wtransCost || null,
    }

    if (postOffer.eventType === '모델') {
      formData.whairStyle = postOffer.whairStyle || null
      formData.wmakeUp = postOffer.wmakeUp || null
    }

    if (postOffer.genre === '노래') {
      formData.wmic = postOffer.wmic || null
    }
    return {
      formData
    }
  },
  computed: {
    step: {
      get() {
        return this.$route.params.step
      },
    },
    postOffer: {
      get() {
        return this.$store.state.postOffer
      },
      set(val) {
        this.$store.commit('setPostOffer', val)
      },
    },
    validate: {
      get() {
        let result = true
        for (let key in this.formData) {
          let val = this.formData[key]
          if (!val) {
            result = false
            break
          }
        }
        return result
      },
    },
  },
  methods: {
    nextStep() {
      this.postOffer = { ...this.formData }
      this.$parent.nextStep()
    },
  },
}
</script>

<style lang="scss" scoped>
#page h3 {
  font-size: 2.2rem;
  line-height: 4rem;
  padding: 2rem 0 0;
  height: 8rem;
  font-weight: 500;
}
</style>
