var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("ProgressBar"),
      _c("page-header", { attrs: { title: "부가 정보를 알려주세요." } }),
      _c("h3", [_vm._v("선호하는 성별")]),
      _c("tag-box", [
        _c(
          "li",
          {
            class: { on: _vm.formData.wgender === "여성" },
            on: {
              click: function($event) {
                _vm.formData.wgender = "여성"
              }
            }
          },
          [_vm._v("여성")]
        ),
        _c(
          "li",
          {
            class: { on: _vm.formData.wgender === "남성" },
            on: {
              click: function($event) {
                _vm.formData.wgender = "남성"
              }
            }
          },
          [_vm._v("남성")]
        ),
        _c(
          "li",
          {
            class: { on: _vm.formData.wgender === "무관" },
            on: {
              click: function($event) {
                _vm.formData.wgender = "무관"
              }
            }
          },
          [_vm._v("무관")]
        )
      ]),
      _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
      _c("h3", [_vm._v("선호하는 연령대")]),
      _c("tag-box", [
        _c(
          "li",
          {
            class: { on: _vm.formData.wage === "10대" },
            on: {
              click: function($event) {
                _vm.formData.wage = "10대"
              }
            }
          },
          [_vm._v("10대")]
        ),
        _c(
          "li",
          {
            class: { on: _vm.formData.wage === "20대" },
            on: {
              click: function($event) {
                _vm.formData.wage = "20대"
              }
            }
          },
          [_vm._v("20대")]
        ),
        _c(
          "li",
          {
            class: { on: _vm.formData.wage === "30대" },
            on: {
              click: function($event) {
                _vm.formData.wage = "30대"
              }
            }
          },
          [_vm._v("30대")]
        ),
        _c(
          "li",
          {
            class: { on: _vm.formData.wage === "40대" },
            on: {
              click: function($event) {
                _vm.formData.wage = "40대"
              }
            }
          },
          [_vm._v("40대")]
        ),
        _c(
          "li",
          {
            class: { on: _vm.formData.wage === "50대" },
            on: {
              click: function($event) {
                _vm.formData.wage = "50대"
              }
            }
          },
          [_vm._v("50대")]
        ),
        _c(
          "li",
          {
            class: { on: _vm.formData.wage === "60대 이상" },
            on: {
              click: function($event) {
                _vm.formData.wage = "60대 이상"
              }
            }
          },
          [_vm._v("60대 이상")]
        ),
        _c(
          "li",
          {
            class: { on: _vm.formData.wage === "무관" },
            on: {
              click: function($event) {
                _vm.formData.wage = "무관"
              }
            }
          },
          [_vm._v("무관")]
        )
      ]),
      _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
      _c("h3", [_vm._v("교통비는 예산에 포함인가요?")]),
      _c("tag-box", [
        _c(
          "li",
          {
            class: { on: _vm.formData.wtransCost === "포함" },
            on: {
              click: function($event) {
                _vm.formData.wtransCost = "포함"
              }
            }
          },
          [_vm._v("포함")]
        ),
        _c(
          "li",
          {
            class: { on: _vm.formData.wtransCost === "별도 지급" },
            on: {
              click: function($event) {
                _vm.formData.wtransCost = "별도 지급"
              }
            }
          },
          [_vm._v("별도 지급")]
        )
      ]),
      _vm.postOffer.eventType === "모델"
        ? [
            _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
            _c("h3", [_vm._v("헤어 스타일링은 제공해주시나요?")]),
            _c("tag-box", [
              _c(
                "li",
                {
                  class: { on: _vm.formData.whairStyle === "제공" },
                  on: {
                    click: function($event) {
                      _vm.formData.whairStyle = "제공"
                    }
                  }
                },
                [_vm._v("제공")]
              ),
              _c(
                "li",
                {
                  class: { on: _vm.formData.whairStyle === "전문가가 준비" },
                  on: {
                    click: function($event) {
                      _vm.formData.whairStyle = "전문가가 준비"
                    }
                  }
                },
                [_vm._v("전문가가 준비")]
              )
            ]),
            _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
            _c("h3", [_vm._v("메이크업은 제공해주시나요?")]),
            _c("tag-box", [
              _c(
                "li",
                {
                  class: { on: _vm.formData.wmakeUp === "제공" },
                  on: {
                    click: function($event) {
                      _vm.formData.wmakeUp = "제공"
                    }
                  }
                },
                [_vm._v("제공")]
              ),
              _c(
                "li",
                {
                  class: { on: _vm.formData.wmakeUp === "전문가가 준비" },
                  on: {
                    click: function($event) {
                      _vm.formData.wmakeUp = "전문가가 준비"
                    }
                  }
                },
                [_vm._v("전문가가 준비")]
              )
            ])
          ]
        : _vm._e(),
      _vm.postOffer.genre === "노래"
        ? [
            _c("div", { staticClass: "ui-border-line ui-mt-1 ui-mb-1" }),
            _c("h3", [_vm._v("음향 장비는 제공해주시나요?")]),
            _c("tag-box", [
              _c(
                "li",
                {
                  class: { on: _vm.formData.wmic === "제공" },
                  on: {
                    click: function($event) {
                      _vm.formData.wmic = "제공"
                    }
                  }
                },
                [_vm._v("제공")]
              ),
              _c(
                "li",
                {
                  class: { on: _vm.formData.wmic === "전문가가 준비" },
                  on: {
                    click: function($event) {
                      _vm.formData.wmic = "전문가가 준비"
                    }
                  }
                },
                [_vm._v("전문가가 준비")]
              )
            ])
          ]
        : _vm._e(),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-6" }),
      _c("footer-box", {
        attrs: {
          submitText: "다음",
          submitCb: function() {
            return _vm.nextStep()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }